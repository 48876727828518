import React, { useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
// import gql from 'graphql-tag';
import pageQuery from './pageQuery';
import { toComponentName } from '../../utils/toComponentName';
import * as blocks from '../blocks';
import ViewButton from 'components/ViewButton';
import ScrollToTop from 'utils/ScrollToTop';

// Query lives in seperate .js file to keep it clean
const PAGE_QUERY = pageQuery;

// Fetch and display a Page
export const Page = ({ handlePageSlug, ...props }) => {
  const [state, setState] = useState({
    page: {
      title: '',
      content: '',
      uri: '',
      acf: {
        blocks: null,
      },
      viewPageButton: {
        pageLinkText: '',
        showViewPageButton: false,
        viewPageLink: null,
        colour: null,
      },
    },
  });
  // const [showViewButton, setShowViewButton] = useState(false);

  useEffect(() => {
    // Cancel all subscriptions and asynchronous tasks to prevent memory leak
    let requestCancelled = false;

    const executePageQuery = async () => {
      const { match, client } = props;

      let uri = match.url.substr(1);

      if (!uri) {
        uri = 'home';
      }

      const result = await client.query({
        query: PAGE_QUERY,
        variables: { uri },
      });

      if (result) {
        handlePageSlug(result?.data?.pageBy?.slug);
      }

      if (!requestCancelled) {
        const page = result?.data?.pageBy;
        setState({ page });
      }
    };

    // Execute page query, process the response and set the state
    executePageQuery();

    // cancel subscriptions and asynchronous tasks
    return () => {
      requestCancelled = true;
    };
  }, [props.match]); // eslint-disable-line

  const { page } = state;
  const acf_blocks = page?.acf.blocks;

  useEffect(() => {
    if (window.dataLayer && page && page.title) {
      window.dataLayer.push({
        event: 'title-loaded',
        title: page.title,
      });
    }
  }, [page?.title]); // eslint-disable-line

  return (
    <>
      <ScrollToTop />
      {page?.viewPageButton?.showViewPageButton &&
      page?.viewPageButton?.showViewPageButton !== null &&
      page?.viewPageButton?.viewPageLink ? (
        <ViewButton
          text={page.viewPageButton.pageLinkText}
          link={page.viewPageButton.viewPageLink.slug}
          colour={page.viewPageButton.colour}
        />
      ) : null}
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: page?.content,
        }}
      />
      {acf_blocks &&
        acf_blocks.map((block, i) => {
          const name = toComponentName(block.fieldGroupName);
          const Component = blocks[name];

          return <Component {...block} key={i} slug={page?.slug} />;
        })}
    </>
  );
};

export default withApollo(Page);
