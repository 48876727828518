import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';

import './property-app.config';
import { Theme } from 'theme/theme';
import Header from './components/Header';
import Footer from './components/Footer';
import FormModal from './components/Form';
import RefralModal from './components/Form/RefralModal';
// import Home from './pages/home';
import Search from './components/Search';
import Page from './pages/page';
import Legal from './pages/legal';
// import Contact from './pages/contact';
import Post from './pages/post/Post';
import NewsandeventsCategory from './pages/newsandevents/newsandeventsCategory';
import NewsandeventsSearch from './pages/newsandevents/newsandeventsSearch';
import Newsandevents from './pages/newsandevents';
import VrTour from './pages/vrTour';

// property App
import HouseAndLand from './pages/houseAndLand';
import LandForSale from './pages/landForSale';

import 'swiper/swiper.scss';
import 'scss/main.scss';
import styles from './App.module.scss';

// ipad
import Ipad from './pages/ipad';

const App = client => {
  const [pageSlug, setPageSlug] = useState(null);
  const handlePageSlug = slug => setPageSlug(slug);
  return (
    <Theme>
      {window.location.pathname !== '/reception' ? <Header /> : null}

      <main className={styles.main}>
        <Switch>
          <Route path="/house-and-land" component={HouseAndLand} exact />
          <Route path="/house-and-land/*" component={HouseAndLand} />
          <Route path="/land-for-sale" component={LandForSale} exact />
          <Route path="/land-for-sale/*" component={LandForSale} />
          <Route path="/land-for-sale-results" component={LandForSale} />
          <Route path="/land-for-sale-results/*" component={LandForSale} />
          <Route path="/house-and-land" component={HouseAndLand} exact />
          <Route path="/house-and-land/*" component={HouseAndLand} />
          {/* <Route exact path="/contact" component={Contact} /> */}
          <Route exact path="/terms-and-conditions" component={Legal} />
          <Route exact path="/disclaimer" component={Legal} />
          <Route exact path="/privacy-policy" component={Legal} />
          <Route exact path="/search" component={Search} />
          <Route exact path="/news-and-events" component={Newsandevents} />
          <Route exact path="/vr-tour" component={VrTour} />
          <Route exact path="/news-and-events/:slug" component={Post} />
          <Route
            exact
            path="/category/:slug"
            component={NewsandeventsCategory}
          />
          <Route exact path="/search/:slug" component={NewsandeventsSearch} />
          <Route exact path="/reception" component={Ipad} />
          <Route
            exact
            path="/ofdkrg2b8ho4hwr41lgpepp15uqizj.html"
            render={() => {
              window.location.href = 'ofdkrg2b8ho4hwr41lgpepp15uqizj.html';
            }}
          />

          <Route
            exact
            path="*"
            render={props => (
              <Page handlePageSlug={handlePageSlug} {...props} />
            )}
          />
        </Switch>
      </main>
      {window.location.pathname !== '/reception' ? (
        <>
          <Footer pageSlug={pageSlug} /> <FormModal />
        </>
      ) : null}

      {window.location.pathname !== '/ktok-campaign-landing' ? (
        <>
          <RefralModal />
        </>
      ) : null}
    </Theme>
  );
};

export default App;
