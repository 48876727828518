import React, { useContext, useState, useEffect } from 'react';
import useWindowSize from '@react-hook/window-size';
import { isBrowser } from 'react-device-detect';
import classnames from 'classnames';
import ScrollLock from 'react-scrolllock';

import { NavLink, Link } from 'react-router-dom';
import Icon from 'components/Icon';

import { ThemeContext } from 'theme/theme';

import styles from './Navigation.module.scss';

import { stripBaseUrl } from 'utils/stripBaseUrl';

import { ReactComponent as Logo } from 'static/images/wiltongreens.svg';

// Checks if urltype is internal or external
const isExternal = urltype => urltype.includes('_blank');

export const Navigation = ({ data, headerTop }) => {
  const [width] = useWindowSize();
  const [lockScroll, setLockScroll] = useState(false);
  const { editableRegionLeft, editableRegionRight } = headerTop;
  const {
    toggleModalForm,
    toggleNav,
    nav,
    setFormTypeRegister,
    setFormTypeDownload,
    closeNav,
  } = useContext(ThemeContext);
  const navItems = data.navItems;
  const c2a = data.headerTop;

  const showForm = () => {
    setFormTypeDownload();
    toggleModalForm();

    // console.log(lockScroll);
  };

  const onClick = () => {
    toggleModalForm();
    setFormTypeRegister();
  };

  useEffect(() => {
    if (nav) {
      setLockScroll(true);
    } else {
      setLockScroll(false);
    }
  }, [nav]);

  useEffect(() => {
    if (width > 1024 && nav) {
      closeNav();
    }
  }, [width]); //eslint-disable-line

  return (
    <div className={styles.navbar}>
      <div className={classnames(styles.container, 'container')}>
        <Link to="/">
          <Logo className={styles.logo} />
        </Link>

        <nav className={classnames(styles.nav, { [styles.active]: nav })}>
          <ScrollLock isActive={lockScroll}>
            <div className={styles.inner}>
              <div className={styles.top}>
                <ul>
                  {navItems &&
                    navItems.map((item, i) => {
                      if (item.node.target && isExternal(item.node.target)) {
                        return (
                          <li key={i}>
                            <a
                              key={item.node.label}
                              href={item.node.url}
                              className={classnames(
                                { [styles.isBrowser]: isBrowser },
                                'ml1 no-underline black',
                              )}
                              target="_blank"
                              rel="noopener noreferrer"
                              // onClick={()=>toggle()}
                            >
                              {item.node.label}
                            </a>
                          </li>
                        );
                      }
                      return (
                        <li key={i}>
                          <NavLink
                            activeClassName={styles.activeNavLink}
                            key={item.node.label}
                            to={stripBaseUrl(item.node.url)}
                            className={classnames(
                              { [styles.isBrowser]: isBrowser },
                              'ml1 no-underline black',
                            )}
                            {...(window.innerWidth <= 1024 && {
                              onClick: () => toggleNav(),
                            })}
                          >
                            {item.node.label}
                          </NavLink>

                          <ul>
                            {item.node.childItems &&
                              item.node.childItems.edges.map((item, i) => {
                                return (
                                  <li key={i}>
                                    <Link
                                      className={classnames({
                                        [styles.isBrowser]: isBrowser,
                                      })}
                                      to={item.node.url}
                                      {...(window.innerWidth <= 1024 && {
                                        onClick: () => toggleNav(),
                                      })}
                                    >
                                      {item.node.label}
                                    </Link>
                                  </li>
                                );
                              })}
                          </ul>
                        </li>
                      );
                    })}
                  {/* <li key={-1}><a href="tel:133188" className={styles.phone}>Call us on 133 188<Icon name="phone" className={ styles.phoneicon } /></a></li> */}
                  <li key={-2} className={styles.register} onClick={onClick}>
                    <span>{c2a && c2a.registerButtonText}</span>
                  </li>
                </ul>
                <div className={classnames(styles.regionLeft, styles.regions)}>
                  {editableRegionLeft.linkType === 'form' ? (
                    <div onClick={showForm}>
                      {editableRegionLeft.text}
                      <Icon name="arrow" className={styles.arrow} />
                    </div>
                  ) : editableRegionLeft.newWindow ? (
                    <a
                      href={editableRegionLeft.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {editableRegionLeft.text}
                      <Icon name="arrow" className={styles.arrow} />
                    </a>
                  ) : (
                    <Link to={editableRegionLeft.link}>
                      {editableRegionLeft.text}
                      <Icon name="arrow" className={styles.arrow} />
                    </Link>
                  )}
                </div>
              </div>
              <div className={styles.bottom}>
                <div className={classnames(styles.regionRight, styles.regions)}>
                  {editableRegionRight.newWindow ? (
                    <>
                      <a href="tel:133188" className={styles.call}>
                        Call us on 133 188
                        <Icon name="phone" className={styles.phoneicon} />
                      </a>
                      <a
                        href={editableRegionRight.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {editableRegionRight.text}
                        <Icon name="marker" className={styles.marker} />
                      </a>
                    </>
                  ) : (
                    <Link to={editableRegionRight.link} className={styles.call}>
                      {editableRegionRight.text}
                      <Icon name="marker" className={styles.marker} />
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </ScrollLock>
        </nav>

        <div className={styles.phoneWrapper}>
          <a href="tel:133188" className={styles.phone}>
            <Icon name="phone" className={styles.phoneicon} />
          </a>
        </div>
        <div
          className={classnames(styles.toggleNav, { [styles.active]: nav })}
          onClick={toggleNav}
        >
          <div className={styles.line}></div>
          <div className={styles.line}></div>
          <div className={styles.line}></div>

          <div className={styles.close} />
        </div>
      </div>
    </div>
  );
};

export default Navigation;
