import React, { createContext, useState } from 'react';

export const ThemeContext = createContext();

export const Theme = ({ children }) => {
  const [settings, setSettings] = useState({
    colors: [
      { name: 'white', color: '#fff' },
      { name: 'green-300', color: '#00AF6B' },
      { name: 'green-600', color: '#004E44' },
      { name: 'green-900', color: '#003931' },
      { name: 'orange-500', color: '#FF614D' },
      { name: 'yellow-500', color: '#FFB000' },
      { name: 'blue-500', color: '#002C3F' },
      { name: 'red-600', color: '#c53b00' },
    ],
    modalForm: false,
    refralModalForm: false,
    nav: false,
    tiktokCampaignMailSent: false,
  });

  const [formTypeSettings, setFormTypeSettings] = useState({
    formType: 'register',
  });

  const [viewButtonSettings, setViewButtonSettings] = useState({
    heroHeight: 1000,
    footerFromTop: 3000,
    featureImageFromTop: null,
    featureImageHeight: null,
  });

  return (
    <ThemeContext.Provider
      value={{
        colors: settings.colors,
        modalForm: settings.modalForm,
        refralModalForm: settings.refralModalForm,
        nav: settings.nav,
        formType: formTypeSettings.formType,
        heroHeight: viewButtonSettings.heroHeight,
        footerFromTop: viewButtonSettings.footerFromTop,
        featureImageFromTop: viewButtonSettings.featureImageFromTop,
        featureImageHeight: viewButtonSettings.featureImageHeight,
        tiktokCampaignMailSent: settings.tiktokCampaignMailSent,

        setFeatureImage: (top, height) => {
          setViewButtonSettings({
            ...viewButtonSettings,
            featureImageFromTop: top,
            featureImageHeight: height,
          });
        },

        setHeroHeight: height => {
          setViewButtonSettings({ ...viewButtonSettings, heroHeight: height });
        },
        setFooterFromTop: fromTop => {
          setViewButtonSettings({
            ...viewButtonSettings,
            footerFromTop: fromTop,
          });
        },
        setFormTypeDownload: () => {
          setFormTypeSettings({ ...formTypeSettings, formType: 'download' });
        },
        setFormTypeRegister: () => {
          setFormTypeSettings({ ...formTypeSettings, formType: 'register' });
        },
        setFormTypeLocalGuide: () => {
          setFormTypeSettings({ ...formTypeSettings, formType: 'localguide' });
        },
        toggleModalForm: () => {
          setSettings({ ...settings, modalForm: !settings.modalForm });
        },
        toggleRefralModalForm: () => {
          setSettings({
            ...settings,
            refralModalForm: !settings.refralModalForm,
          });
        },
        toggleNav: () => {
          setSettings({ ...settings, nav: !settings.nav });
        },
        closeNav: () => {
          setSettings({ ...settings, nav: false });
        },
        updateTiktokCampaignMailSent: () => {
          setSettings({ ...settings, tiktokCampaignMailSent: true });
        },
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default Theme;
