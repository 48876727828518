import React, { useState, useRef, useEffect } from 'react';
import Icon from 'components/Icon';
import classnames from 'classnames';

import { ReactComponent as SwipeHint } from "static/images/swipehint.svg";
import illustrationamp from 'static/images/illustrationmap/illustrationmap.png';
import wilton from 'static/images/illustrationmap/wilton.png';
import shark from 'static/images/illustrationmap/shark.gif';
import surfer1 from 'static/images/illustrationmap/surfer1.gif';
import surfer2 from 'static/images/illustrationmap/surfer2.gif';
import whale from 'static/images/illustrationmap/whale.gif';
import bikes from 'static/images/illustrationmap/bikes.gif';
import boat from 'static/images/illustrationmap/boat.gif';
import canoe from 'static/images/illustrationmap/canoe.gif';
import car1 from 'static/images/illustrationmap/car1.gif';
import car2 from 'static/images/illustrationmap/car2.gif';
import car3 from 'static/images/illustrationmap/car3.gif';
import car4 from 'static/images/illustrationmap/car4.gif';
import fountain from 'static/images/illustrationmap/fountain.gif';
import globe from 'static/images/illustrationmap/globe.gif';
import parliment from 'static/images/illustrationmap/parliment.gif';
import plane_clouds from 'static/images/illustrationmap/plane_clouds.gif';

import styles from './IllustrationMap.module.scss';

export const IllustrationMap = () => {
  const [infoWindow, toggleInfoWindow] = useState({
    windows: [
      { name: 'wilton', active: false },
      { name: 'bikes', active: false },
      { name: 'surfer2', active: false },
      { name: 'canoe', active: false },
      { name: 'car4', active: false },
      { name: 'macarthur', active: false },
      { name: 'newtown', active: false },
      { name: 'grapes', active: false },
      { name: 'drunks', active: false },
      { name: 'hospital', active: false },
      { name: 'picton', active: false },
    ],
  });

  const [hint, setHint] = useState(true);

  const scrollElm = useRef();
  const swipeElm = useRef();

  const toggleStates = name => {
    let newArray = infoWindow.windows;
    newArray.forEach(item => {
      console.log(item, name);
      item.name === name ? (item.active = true) : (item.active = false);
    });

    toggleInfoWindow({ ...infoWindow, windows: newArray });
  };

  useEffect(() => {
    scrollElm.current.scrollLeft = 300;
  }, []);

  const removeHint = () => {
    swipeElm.current.remove();
    setHint(false);
  }

  return (
    <section className={styles.illustrationmap}>
      <div className="container">
        <div className={styles.helper}>
          <Icon name="hand" className={styles.hand} />
          Explore the map to discover more information
        </div>
      </div>
      <div className={styles.themapWrapper}>
        <div className={styles.swipehint} ref={swipeElm} onTouchStart={removeHint} onClick={removeHint}>
          <SwipeHint />
        </div>
        <div className={styles.scroller} ref={scrollElm}>
          <div className={classnames(styles.themap, {[styles.hint] : hint})}>
            <div className={classnames(styles.darkoverlay, { [styles.hint] : hint })} />
            <img
              src={illustrationamp}
              title="Illustration Map"
              alt="Illustration Map"
              className={styles.baselayer}
            />
            <img
              src={wilton}
              alt="wilton"
              className={styles.wilton}
              onClick={() => toggleStates('wilton')}
            />

            <div
              className={classnames(styles.infoWindow, styles.infoWindowWilton, {
                [styles.active]: infoWindow.windows[0].active,
              })}
            >
              <Icon
                name="close"
                className={styles.close}
                onClick={() => toggleStates('')}
              />
              <Icon name="marker" className={styles.marker} />
              <h3>Wilton Greens</h3>
              <p>
                Strategically designed for a more sustainable and self-sufficient
                community, this superior masterplan offers you the convenience of
                parks, playgrounds, retailers, education, employment and public
                transport all on your doorstep.
              </p>
            </div>

            <img src={shark} alt="shark" className={styles.shark} />
            <img src={surfer1} alt="surfer" className={styles.surfer1} />

            <img src={whale} alt="whale" className={styles.whale} />

            <img src={boat} alt="boat" className={styles.boat} />

            <img src={car1} alt="car" className={styles.car1} />
            <img src={car2} alt="car" className={styles.car2} />
            <img src={car3} alt="car" className={styles.car3} />
            {/* bikes */}
            <img
              src={bikes}
              alt="bikes"
              className={styles.bikes}
              onClick={() => toggleStates('bikes')}
            />

            <div
              className={classnames(styles.infoWindow, styles.infoWindowBikes, {
                [styles.active]: infoWindow.windows[1].active,
              })}
            >
              <Icon
                name="close"
                className={styles.close}
                onClick={() => toggleStates('')}
              />
              <Icon name="marker" className={styles.marker} />
              <h3>Jogging & Cycling</h3>
              <p>
                Explore the local nature and forests with various bike trails in
                the surrounding area.
              </p>
            </div>

            {/* surfer 2 */}
            <img
              src={surfer2}
              alt="surfer"
              className={styles.surfer2}
              onClick={() => toggleStates('surfer2')}
            />

            <div
              className={classnames(
                styles.infoWindow,
                styles.right,
                styles.infoWindowSurfer2,
                {
                  [styles.active]: infoWindow.windows[2].active,
                },
              )}
            >
              <Icon
                name="close"
                className={styles.close}
                onClick={() => toggleStates('')}
              />
              <Icon name="marker" className={styles.marker} />
              <h3>Surf’s up at Wollongong</h3>
              <p>
                As the gateway to south coast beaches, Wollongong’s magnificent
                surf beaches are close by, just 40 mins from your door at Wilton
                Greens.
              </p>
            </div>

            {/* canoe */}
            <img
              src={canoe}
              alt="canoe"
              className={styles.canoe}
              onClick={() => toggleStates('canoe')}
            />
            <div
              className={classnames(styles.infoWindow, styles.infoWindowCanoe, {
                [styles.active]: infoWindow.windows[3].active,
              })}
            >
              <Icon
                name="close"
                className={styles.close}
                onClick={() => toggleStates('')}
              />
              <Icon name="marker" className={styles.marker} />
              <h3>
                Explore the majestic <br />
                Nepean River
              </h3>
              <p>
                Adventure awaits at this glorious river, with rapids, gorges and
                one of the deepest swimming holes in NSW adding to the spectacular
                scenery.
              </p>
            </div>

            {/* car */}
            <img
              src={car4}
              alt="car"
              className={styles.car4}
              onClick={() => toggleStates('car4')}
            />
            <div
              className={classnames(styles.infoWindow, styles.infoWindowCar, {
                [styles.active]: infoWindow.windows[4].active,
              })}
            >
              <Icon
                name="close"
                className={styles.close}
                onClick={() => toggleStates('')}
              />
              <Icon name="marker" className={styles.marker} />
              <h3>Perfectly positioned</h3>
              <p>
                Live at the crossroads of Sydney, Canberra and Wollongong, with
                transport links closeby and direct access to the M31 Hume Highway.
              </p>
            </div>

            {/* macarthur */}
            <div
              className={classnames(styles.macarthur, styles.hotbox)}
              onClick={() => toggleStates('macarthur')}
            ></div>
            <div
              className={classnames(
                styles.infoWindow,
                styles.infoWindowMacarthur,
                { [styles.active]: infoWindow.windows[5].active },
              )}
            >
              <Icon
                name="close"
                className={styles.close}
                onClick={() => toggleStates('')}
              />
              <Icon name="marker" className={styles.marker} />
              <h3>Never far from a shopping trip</h3>
              <p>
                With over 300 shops Macarthur Square Shopping Centre includes all
                the department stores you could ever need.
              </p>
            </div>

            {/* newtown */}
            <div
              className={classnames(styles.newtown, styles.hotbox)}
              onClick={() => toggleStates('newtown')}
            ></div>
            <div
              className={classnames(styles.infoWindow, styles.infoWindowNewtown, {
                [styles.active]: infoWindow.windows[6].active,
              })}
            >
              <Icon
                name="close"
                className={styles.close}
                onClick={() => toggleStates('')}
              />
              <Icon name="marker" className={styles.marker} />
              <h3>Discover a new Town Centre</h3>
              <p>
                Living at Wilton Greens will put you right next to a huge variety
                of services in the Wilton New Town Centre.
              </p>
            </div>

            {/* grapes */}
            <div
              className={classnames(styles.grapes, styles.hotbox)}
              onClick={() => toggleStates('grapes')}
            ></div>
            <div
              className={classnames(styles.infoWindow, styles.infoWindowGrapes, {
                [styles.active]: infoWindow.windows[7].active,
              })}
            >
              <Icon
                name="close"
                className={styles.close}
                onClick={() => toggleStates('')}
              />
              <Icon name="marker" className={styles.marker} />
              <h3>Take the time to indulge</h3>
              <p>
                Discover award-winning wines, modern cellar doors, acclaimed
                restaurants and classic country pubs.
              </p>
            </div>

            {/* drunks */}
            <div
              className={classnames(styles.drunks, styles.hotbox)}
              onClick={() => toggleStates('drunks')}
            ></div>
            <div
              className={classnames(styles.infoWindow, styles.infoWindowDrunks, {
                [styles.active]: infoWindow.windows[8].active,
              })}
            >
              <Icon
                name="close"
                className={styles.close}
                onClick={() => toggleStates('')}
              />
              <Icon name="marker" className={styles.marker} />
              <h3>Enjoy a taste of the Highland</h3>
              <p>
                The dining scene across the region is awash with high calibre
                restaurants and fine dining experiences
              </p>
            </div>

            {/* hospital */}
            <div
              className={classnames(styles.hospital, styles.hotbox)}
              onClick={() => toggleStates('hospital')}
            ></div>
            <div
              className={classnames(
                styles.infoWindow,
                styles.infoWindowHospital,
                {
                  [styles.active]: infoWindow.windows[9].active,
                },
              )}
            >
              <Icon
                name="close"
                className={styles.close}
                onClick={() => toggleStates('')}
              />
              <Icon name="marker" className={styles.marker} />
              <h3>Health & well-being</h3>
              <p>
                Three major hospital and healthcare facilities are within easy
                reach.
              </p>
            </div>

            {/* picton */}
            <div
              className={classnames(styles.picton, styles.hotbox)}
              onClick={() => toggleStates('picton')}
            ></div>
            <div
              className={classnames(styles.infoWindow, styles.infoWindowPicton, {
                [styles.active]: infoWindow.windows[10].active,
              })}
            >
              <Icon
                name="close"
                className={styles.close}
                onClick={() => toggleStates('')}
              />
              <Icon name="marker" className={styles.marker} />
              <h3>Discover a fascinating past</h3>
              <p>
                Originally known as Stonequarry, Picton is home to many historic
                buildings, bridges and heritage listed railways making it a must
                for a day trip.
              </p>
            </div>

            <img src={fountain} alt="fountain" className={styles.fountain} />
            <img src={globe} alt="globe" className={styles.globe} />
            <img src={plane_clouds} alt="plane" className={styles.plane_clouds} />
            <img src={parliment} alt="parliment" className={styles.parliment} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default IllustrationMap;
