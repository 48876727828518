import React, { useCallback, useContext, useState, useEffect } from 'react';
import propertyAppConfig from 'react-property-app/config';
import classnames from 'classnames';
import { useForm } from 'react-hook-form';
import Button from 'components/Button';
import { do_inSiteReg } from 'utils/insite';
import $ from 'jquery';

import { ThemeContext } from 'theme/theme';

import { ReactComponent as Close } from './img/close.svg';

import styles from './FormModal.module.scss';

export const FormModal = () => {
  const { register, handleSubmit, errors, watch } = useForm();
  const [mailsent, setMailsent] = useState(false);

  const [pkg, setPkg] = useState(null);

  const {
    modalForm,
    toggleModalForm,
    formType,
    setFormTypeRegister,
  } = useContext(ThemeContext);

  const watchAllFields = watch();

  const toggleOpen = useCallback(
    pkg => {
      if (!modalForm) {
        setFormTypeRegister();
        toggleModalForm(true);
      }

      setPkg(pkg);
    },
    // eslint-disable-next-line
    [modalForm],
  );

  useEffect(() => {
    if (formType === 'register') {
      propertyAppConfig.set('enquiryForm', {
        function: toggleOpen,
      });
    }
  }, [toggleOpen, formType]);

  const { name } = pkg || {};

  const onSubmit = data => {
    let mailData = data;
    setMailsent('loading');
    do_inSiteReg(mailData).then(() => {
      $.post('https://s1481342642.t.eloqua.com/e/f2', {
        firstName: data.firstName,
        lastName: data.lastName,
        name: data.name,
        email: data.email,
        phoneNumber: data.phoneNumber,
        postcode: data.postcode,
        elqFormName: eloqua.formName,
        elqSiteID: eloqua.formID,
        elqCustomerGUID: '',
        elqCookieWrite: '0',
        utm_source: data.utm_source,
        utm_medium: data.utm_medium,
        utm_campaign: data.utm_campaign,
        utm_term: data.utm_term,
        utm_content: data.utm_content,
      }).done(() => {
        setMailsent(true);
        window.dataLayer.push({
          event: 'form-successful',
        });
      });
    });
  };
  const registerForm = {
    headlinePart1: (
      <div dangerouslySetInnerHTML={{ __html: 'Register<br />Today' }} />
    ),
    headlinePart2: name ? (
      <span>
        Fill in your details to find out
        <br /> more information on {name}
      </span>
    ) : (
      ''
    ),
    headlinePart1Bold: true,
    headlinePart2Bold: false,
    paragraph: '',
    fields: ['firstname', 'lastname', 'email', 'phonenumber'],
    buttonLabel: 'Register Today',
  };

  const registerThankyou = {
    headlinePart1: 'Thank you ',
    headlinePart2: 'For your interest',
    headlinePart1Bold: true,
    headlinePart2Bold: false,
    paragraph:
      'Thank you for your interest. We have received your details and will be in touch shortly.',
    buttonLabel: 'Back to Page',
    download: '',
  };

  const registerEloqua = {
    formID: 1481342642,
    formName: 'wilton-greens-eoi',
  };

  const downloadForm = {
    headlinePart1: 'Register Today',
    headlinePart2: '',
    headlinePart1Bold: true,
    paragraph:
      'Fill in your details below to download the Amenities Map for Wilton Greens.',
    fields: ['name', 'email'],
    buttonLabel: 'Register and Download',
  };

  const downloadThankyou = {
    headlinePart1: 'Thank you',
    headlinePart2: '',
    headlinePart1Bold: true,
    headlinePart2Bold: false,
    paragraph:
      'Thank you for your interest. We have received your details and will be in touch shortly.',
    buttonLabel: 'Download Amenities Map',
    download: '/wp-content/uploads/2020/08/WiltonGreens_Amenities_20200805.pdf',
  };

  const downloadEloqua = {
    formID: 1481342642,
    formName: 'wilton-greens-downloadforms',
  };

  const localGuideForm = {
    headlinePart1: 'Register Today',
    headlinePart2: '',
    headlinePart1Bold: true,
    paragraph:
      'Fill in your details below to download the Neighbourhood Guide for Wilton Greens.',
    fields: ['name', 'email'],
    buttonLabel: 'Register and Download',
  };

  const localGuideThankyou = {
    headlinePart1: 'Thank you',
    headlinePart2: '',
    headlinePart1Bold: true,
    headlinePart2Bold: false,
    paragraph:
      'Thank you for your interest. We have received your details and will be in touch shortly.',
    buttonLabel: 'Download your Neighbourhood Guide',
    download:
      '/wp-content/uploads/2020/07/WiltonGreens_LocalGuide_20200801.pdf',
  };

  const localGuideEloqua = {
    formID: 1481342642,
    formName: 'wilton-greens-downloadforms',
  };

  const form =
    formType === 'download'
      ? downloadForm
      : formType === 'localguide'
      ? localGuideForm
      : registerForm;

  const thankyou =
    formType === 'download'
      ? downloadThankyou
      : formType === 'localguide'
      ? localGuideThankyou
      : registerThankyou;

  const eloqua =
    formType === 'download'
      ? downloadEloqua
      : formType === 'localguide'
      ? localGuideEloqua
      : registerEloqua;

  return (
    <>
      <div className={classnames(styles.modal, { [styles.active]: modalForm })}>
        <div className={styles.modal_closearea} onClick={toggleModalForm} />
        <div
          className={classnames(styles.modal_content, {
            [styles.active]: modalForm,
          })}
        >
          <div className={styles.close} onClick={toggleModalForm}>
            <Close className={styles.icon} />
          </div>

          <form
            className={classnames(styles.form)}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div
              className={classnames(styles.form_content, {
                [styles.active]: modalForm,
              })}
            >
              <div
                className={classnames(styles.thefields, {
                  [styles.active]: !mailsent,
                })}
              >
                <div
                  className={classnames(styles.form_heading, styles.form_group)}
                >
                  <h3 className={styles.h3}>
                    {form.headlinePart1 && form.headlinePart1}{' '}
                    {form.headlinePart2 && form.headlinePart2}
                  </h3>
                  {form.paragraph && (
                    <div className={styles.description}>{form.paragraph}</div>
                  )}
                </div>

                {form.fields.includes('firstname') && (
                  <div className={styles.formgroup}>
                    <input
                      type="text"
                      name="firstName"
                      id={`firstName`}
                      autoComplete="off"
                      className={classnames(styles.input, {
                        [styles.errorField]: errors.firstName,
                        [styles.active]: watchAllFields.firstName,
                      })}
                      ref={register({ required: true })}
                    />
                    <label
                      className={classnames(styles.label)}
                      htmlFor={`firstName`}
                    >
                      First name*
                    </label>
                    <div className={styles.errorMssg}>
                      {errors.firstName && 'First name is required.'}
                    </div>
                  </div>
                )}

                {form.fields.includes('lastname') && (
                  <div className={styles.formgroup}>
                    <input
                      type="text"
                      name="lastName"
                      id={`lastName`}
                      autoComplete="off"
                      className={classnames(styles.input, {
                        [styles.errorField]: errors.lastName,
                        [styles.active]: watchAllFields.lastName,
                      })}
                      ref={register({ required: true })}
                    />
                    <label
                      className={classnames(styles.label)}
                      htmlFor={`lastName`}
                    >
                      Last name*
                    </label>
                    <div className={styles.errorMssg}>
                      {errors.lastName && 'Last name is required.'}
                    </div>
                  </div>
                )}

                {form.fields.includes('name') && (
                  <div className={styles.formgroup}>
                    <input
                      type="text"
                      name="name"
                      id={`name`}
                      autoComplete="off"
                      className={classnames(styles.input, {
                        [styles.errorField]: errors.name,
                        [styles.active]: watchAllFields.name,
                      })}
                      ref={register({ required: true })}
                    />
                    <label
                      className={classnames(styles.label)}
                      htmlFor={`name`}
                    >
                      Name*
                    </label>
                    <div className={styles.errorMssg}>
                      {errors.name && 'Name is required.'}
                    </div>
                  </div>
                )}

                {form.fields.includes('email') && (
                  <div className={styles.formgroup}>
                    <input
                      type="email"
                      name="email"
                      id={`email`}
                      autoComplete="off"
                      className={classnames(styles.input, {
                        [styles.errorField]: errors.email,
                        [styles.active]: watchAllFields.email,
                      })}
                      ref={register({
                        required: true,
                        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      })}
                    />
                    <label
                      className={classnames(styles.label)}
                      htmlFor={`email`}
                    >
                      Email*
                    </label>
                    <div className={styles.errorMssg}>
                      {errors.email && 'Email is required.'}
                    </div>
                  </div>
                )}

                {form.fields.includes('phonenumber') && (
                  <div className={styles.formgroup}>
                    <input
                      type="text"
                      name="phoneNumber"
                      id={`phoneNumber`}
                      autoComplete="off"
                      className={classnames(styles.input, {
                        [styles.errorField]: errors.phoneNumber,
                        [styles.active]: watchAllFields.phoneNumber,
                      })}
                      ref={register({
                        required: true,
                        pattern: /^(\+\d{2}[ -]{0,1}){0,1}(((\({0,1}[ -]{0,1})0{0,1}\){0,1}[2|3|7|8]{1}\){0,1}[ -]*(\d{4}[ -]{0,1}\d{4}))|(1[ -]{0,1}(300|800|900|902)[ -]{0,1}((\d{6})|(\d{3}[ -]{0,1}\d{3})))|(13[ -]{0,1}([\d -]{5})|((\({0,1}[ -]{0,1})0{0,1}\){0,1}4{1}[\d -]{8,10})))$/,
                      })}
                    />
                    <label
                      className={classnames(styles.label)}
                      htmlFor={`phoneNumber`}
                    >
                      Phone number*
                    </label>
                    <div className={styles.errorMssg}>
                      {errors.phoneNumber && 'Phone number is required.'}
                    </div>
                  </div>
                )}

                <div className={styles.formgroup}>
                  <input
                    type="text"
                    name="postcode"
                    id={`postcode`}
                    autoComplete="off"
                    className={classnames(styles.input, {
                      [styles.errorField]: errors.postcode,
                      [styles.active]: watchAllFields.postcode,
                    })}
                    ref={register({ required: true })}
                  />
                  <label
                    className={classnames(styles.label)}
                    htmlFor={`postcode`}
                  >
                    Postcode*
                  </label>
                  <div className={styles.errorMssg}>
                    {errors.postcode && 'Postcode is required.'}
                  </div>
                </div>

                <div className={styles.formgroup}>
                  <input
                    type="checkbox"
                    id={`agreeTermsModal`}
                    name="agreeTerms"
                    className={classnames(styles.checkbox, {
                      [styles.errorField]: errors.agreeTerms,
                    })}
                    ref={register({ required: true })}
                  />
                  <label
                    htmlFor={`agreeTermsModal`}
                    className={styles.agreeTermslabel}
                  >
                    I have read and accepted the Privacy Policy and the Terms of
                    Use.
                    <div className={styles.errorMssg}>
                      {errors.agreeTerms &&
                        'You must accept the Privacy Policy and T&C.'}
                    </div>
                  </label>
                </div>

                <input
                  type="hidden"
                  name="elqFormName"
                  value={eloqua.formName}
                  ref={register()}
                />
                <input
                  type="hidden"
                  name="elqSiteID"
                  value={eloqua.formID}
                  ref={register()}
                />
                <input
                  type="hidden"
                  name="elqCustomerGUID"
                  value=""
                  ref={register()}
                />
                <input
                  type="hidden"
                  name="elqCookieWrite"
                  value="0"
                  ref={register()}
                />
                <input type="hidden" name="utm_source" ref={register()} />
                <input type="hidden" name="utm_medium" ref={register()} />
                <input type="hidden" name="utm_campaign" ref={register()} />
                <input type="hidden" name="utm_term" ref={register()} />
                <input type="hidden" name="utm_content" ref={register()} />
                <Button
                  submit
                  className={styles.button}
                  capitalized
                  disabled={mailsent}
                  size="md"
                >
                  {mailsent === 'loading' ? 'Loading...' : form.buttonLabel}
                </Button>
              </div>

              <div
                className={classnames(styles.confirmation, {
                  [styles.active]: mailsent,
                })}
              >
                <div
                  className={classnames(styles.form_heading, styles.formgroup)}
                >
                  <h3 className={styles.h3}>
                    {thankyou.headlinePart1 && thankyou.headlinePart1}
                    <br />
                    {thankyou.headlinePart2 && (
                      <span>{thankyou.headlinePart2}</span>
                    )}
                  </h3>
                  <div className={styles.description}>{thankyou.paragraph}</div>
                </div>

                <Button
                  {...(thankyou.download
                    ? { external: true, link: thankyou.download }
                    : { div: true, onClick: toggleModalForm })}
                  className={styles.button}
                  capitalized
                  size="md"
                >
                  {thankyou.buttonLabel}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FormModal;
